@import '../../../styles/common';

.header-bar {
  background-color: $pink;
  padding: 4em 4em 2em;
  display: flex;
  width: calc(100% - 8em);
  justify-content: space-between;

  &.pink {
    background-color: $pink;
  }

  &.purple {
    background-color: $purple;
  }

  img {
    height: 3em;
    width: auto;
  }

  .menu-wrapper {
    a {
      text-decoration: none;
      color: white;
      text-transform: uppercase;
      font-size: 1.5em;
      margin: 0 1em;
      
      &.active {
        font-weight: $font-weight-bold;
      }
    }
  }
}