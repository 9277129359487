@import '../../../../styles/common.scss';

.genre-playlist-tile {
  color: $white;
  

  .tile-wrapper {
    border-radius: 2em;
    position: relative;

    img {
      width: 100%;
      height: 100%;
      line-height: 0;
      border-radius: 2em;
      display: block;
      object-fit: contain;
    }

    .playlist-content {
      position: absolute;
      top: 0;
      left: 0;
      padding: 0 1em;
      max-width: 11em;
      display: flex;
      flex-direction: column;
      height: calc(100% - 2em);
      padding: 1em;

      p {
        margin: 0;
        text-shadow: 0px 0px 6px rgba(0,0,0,0.7);
      }

      .playlist-name {
        font-size: 1.3em;
        flex-grow: 1;
      }

      .playlist-count {
        font-weight: $font-weight-bold;
      }
    }
  }
}