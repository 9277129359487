@import '../../../styles/common';

.flash-message-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  transform: translateY(-100%);
  transition: transform .3s;
  text-align: center;
  color: $white;
  font-size: .8em;
  font-weight: $font-weight-regular;

  &.show {
    transform: translateY(0);
  }

  &.error {
    background-color: $magenta;
  }

  &.info {
    background-color: $green;
  }
}