@import '../../../styles/common.scss';

.playlist-banner-wrapper {
  width: 100vw;
  height: 35vw;
  position: relative;
  margin-bottom: 4em;

  img {
    object-fit: cover;
    height: 100%;
    width: 100%;
  }

  .inner {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-shadow: 0px 0px 3px rgba(0,0,0,0.48);
    background-color: transparentize($black, .8);

    h2 {
      color: $white;
      font-size: 2em;
    }

    h1 {
      font-weight: $font-weight-thin;
      color: $white;
      font-size: 7em;
    }

    p {
      @include contentWidthConstrain();
      width: auto;
      color: white;
      font-size: 1.4em;
      line-height: 1.8em;
    }

    .play-all-button {
      background: $purple;
      color: $white;
      text-decoration: none;
      padding: 1em 6em;
      font-size: 1.5em;
      font-weight: $font-weight-bold;
      border-radius: .3em;
      margin-bottom: -1.5em;
    }
  }
}