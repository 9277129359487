@import '../../styles/common.scss';

.intro-text {
  p {
    color: white;
    font-size: 1.4em;
    font-weight: $font-weight-thin;
    line-height: 1.8em;
  }
}

.collection-wrapper {
  @include contentWidthConstrain;

  .collection-title {
    color: $purple;
    margin-bottom: 2em;
  }

  .collection-tiles-wrapper {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    gap: 2em;
    margin-bottom: 2em;
  }
}