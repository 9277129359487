@import '../../../styles//common.scss';

.background-image-header-wrapper {
  width: 100vw;
  height: auto;
  position: relative;

  img {

    object-fit: cover;
    height: 53vw;
    width: 100vw;
  }

  .inner {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    // margin-top: -40em;


    .title-box {
      text-align: center;
      color: $white;

      h2 {
        font-weight: $font-weight-thin;
        font-size: 5em;
        margin: .3em 0;
      }

      h1 {
        font-weight: $font-weight-bold;
        font-size: 5em;
        margin: .3em 0;
      }
    }

    .content {
      width: 80%;
      display: flex;
      flex: 1;
      flex-direction: column;
      justify-content: center;
      align-content: center;
      max-width: 60em;
    }
  }

}