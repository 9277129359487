@import '../../../../styles/common.scss';

.video-tile-wrapper {
  text-decoration: none;

  .video-tile {
    display: flex;
    border-radius: 1em;
    box-shadow: 0px 0px 7px 0px rgba(0,0,0,0.2);

    .video-tile-image-wrapper {
      width: 45%;

      img {
        width: 100%;
        height: 15em;
        border-radius: 1em 0 0 1em;
        display: block;
      }
    }

    .video-tile-details {
      width: 55%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 2em;
      color: $black;

      p {
        font-size: 1.4em;
      }
    }
  }
}