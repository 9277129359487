@import '../../styles/common.scss';

.search-bar-wrapper {
  background-color: $purple;
  padding: 2em;
}

.search-results-container {
  @include contentWidthConstrain;

  .search-results-playlists-tiles {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    gap: 2em;
    margin-bottom: 2em;    
  }

  .search-results-videos-tiles {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 2em;
    margin-bottom: 2em;
  }
}

.search-results-term {
  @include contentWidthConstrain;

  h2 {
    font-size: 2em;
  }
}