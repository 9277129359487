@import '../../../../styles/common.scss';

.featured-playlist-tile {
  .tile-wrapper {
    border-radius: 2em;
    position: relative;
    height: 100%;

    img {
      width: 100%;
      height: 100%;
      line-height: 0;
      border-radius: 2em;
      display: block;
      object-fit: cover;
    }

    .playlist-content {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      p.playlist-name {
        color: $purple;
        font-size: 1.6em;
        text-align: center;
        padding: .6em 1em;
      }

      p.playlist-count {
        color: $purple;
        text-align: center;
        font-weight: $font-weight-bold;
        background-color: transparentize(white, .5);
        margin: 0;
        border-radius: 0 0 2em 2em;
        padding: .4em;
      }

    }
  }
}