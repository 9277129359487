@import '../../../styles/common.scss';

.field-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 2em;
  align-items: center;
  
  label {
    font-size: 1em;
    font-weight: $font-weight-regular;
    text-transform: uppercase;
    color: $white;
    margin-bottom: .3em;
  }

  input {
    border: none;
    border-radius: .5rem;
    width: 90vw;
    max-width: 40rem;
    font-size: 1.4em;
    font-family: $font;
    padding: .5em;
  }

  p.form-error {
    color: white;
    font-weight: $font-weight-bold;
    font-size: .8em;

  }
}

.login-actions-wrapper {
  text-align: center;

  button.primary {
    background-color: $purple;
    color: $white;
    border: none;
    font-family: $font;
    border-radius: .5rem;
    font-size: 1.3em;
    padding: .6em 1.3em;
    margin: auto;
    cursor: pointer;
  }
}