@import '../../../styles//common.scss';

.search-bar-container {
  display: flex;
  position: relative;

  input {
    // flex: 1;
    font-size: 2em;
    padding: .3em 1em;
    border: none;
    border-radius: .5em 0 0 .5em;
    color: $purple;
    width: calc(100% - 5em);
    height: 1.5em;

    &:focus {
      outline: none;
    }
  }

  button {
    font-size: 1em;
    border-radius: 0 .5em .5em 0 ; 
    border: 0;
    background: $pink;
    padding: auto 2em;
    cursor: pointer;
    width: 5em;
    height: 4.25em;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      background: lighten($pink, 6);
    }

    img {
      height: 3.5em;
      width: 3.5em;
    }
  }

  .search-bar-autocomplete {
    position: absolute;
    left: 0;
    top: 3.5em;
    right: 6em;
    background-color: white;
    border-radius: 0 0 .5em .5em;
    padding: 1em;
    margin: 0;
    list-style-type: none;
    box-shadow: 0px 9px 7px -4px rgba(0,0,0,0.27);
    z-index: 1;

    li {
      a {
        padding: .4em;
        margin-bottom: .5em;
        font-size: 1.7em;
        text-decoration: none;
        display: block;
        color: $purple;

        &:hover {
          background: lighten($pink, .9);
          color: white;
          font-weight: bolder;
        }
      }
    }
  }
}