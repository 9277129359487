@import '../../../styles/common.scss';

.player-container {
  @include contentWidthConstrain();


  h1.player-video-title {
    font-weight: $font-weight-thin;
  }

  .player-inner {
    position: relative;
    height: 0;
    width: 100%;
    padding-top: 55%;

    &.active {
      .player-wrapper {
        left: 0;
      }

      .player-video-cover-wrapper {
        display: none;
      }
    }
  }

  .player-video-cover-wrapper {
    background-color: white;
    z-index: 2;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    border: 1px solid $white;

    .player-video-cover-image {
      height: 100%;
      width: 100%;
      object-fit: cover;
      object-position: center;
      border-radius: 2em;
    }

    .player-video-cover-overlay {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      border-radius: 2em;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 3;

      button {
        border: none;
        background: none;
        height: 15em;
        width: 15em;
        cursor: pointer;
      }
    }
  }

  .loading-wrapper {
    background-color: $white;
    border-radius: 50%;
    height: 9em;
    width: 9em;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .loading-image {
      height: 4em;
      width: 8em;
      object-fit: contain;
    }

    span {
      text-transform: uppercase;
      font-weight: $font-weight-bold;
      color: $purple;
    }
  }

  .player-wrapper {
    height: 100%;
    width: 100%;
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
  }
}

.player-full-screen:fullscreen {
  position: absolute;
  
  .player-controls {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    max-width: none;
    transform: translateY(80%);
    transition: .3s transform;
    
    &:hover {
      transform: translateY(0%);
    }
  }
}

.player-controls {
  @include contentWidthConstrain();
  background: $purple;
  color: $white;

  &.player-:fullscreen {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    width: 100%;
  }

  .player-controls-buttons {
    padding: .4em;
    display: flex;
    align-items: center;

    & > button {
      background: transparent;
      border: 0;
      height: 3em;
      width: 5em;

      img {
        height: 100%;
        width: 100%;
        object-fit: contain;
      }
    }
  }

  .player-scrubber-wrapper{

    .scrubber {
      width: 100%;
      height: 100%;
      position: relative;
      user-select: none;
      touch-action: none;
    }
    .scrubber * {
      user-select: none;
    }
    .scrubber .bar {
      background: rgba(100, 100, 100, 0.5);
      position: relative;
      transition: height 0.2s linear, width 0.2s linear;
    }
    .scrubber.horizontal .bar {
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      height: .25em;
      width: 100%;
    }
    .scrubber.vertical .bar {
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      width: .25em;
      height: 100%;
    }
    .scrubber .bar__progress {
      position: absolute;
      background: $pink;
    }
    .scrubber .bar__buffer {
      position: absolute;
      background: rgba(170, 170, 170, 0.6);
    }
    .scrubber.horizontal .bar__progress,
    .scrubber.horizontal .bar__marker,
    .scrubber.horizontal .bar__buffer {
      height: 100%;
    }

    // .scrubber.horizontal .bar__progress {
    //   transition: width .5s;
    // }
    // .scrubber.horizontal .bar__marker {
    //   transition: left .5s;
    // }

    .scrubber.vertical .bar__progress,
    .scrubber.vertical .bar__marker,
    .scrubber.vertical .bar__buffer {
      width: 100%;
      bottom: 0;
    }
    .scrubber .bar__thumb {
      position: absolute;
      width: 0;
      height: 0;
      border-radius: .6em;
      background: $pink;
      transition: height 0.2s linear, width 0.2s linear;
    }
    .scrubber.horizontal .bar__thumb {
      transform: translate(-50%, -50%);
      top: 50%;
    }
    .scrubber.vertical .bar__thumb {
      transform: translate(-50%, 50%);
      left: 50%;
    }
    .scrubber.hover.horizontal .bar {
      height: .4em;
    }
    .scrubber.hover.vertical .bar {
      width: .4em;
    }
    .scrubber.hover .bar__thumb {
      width: .75em;
      height: .75em;
    }
    
    .scrubber .bar__marker {
      position: absolute;
      background: rgb(240, 205, 5);
    }
    
    .scrubber.horizontal .bar__marker {
      width: .75em;
    }
    
    .scrubber.vertical .bar__marker {
      height: .75em;
    }
  }
}

.player-video-description {
  @include contentWidthConstrain();
  margin: 5em auto !important;
  
  p {
    font-size: 1.6em;
    line-height: 1.4em;
  }
}