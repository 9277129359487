@import '../../../styles/common.scss';

.playlist-wrapper {
  @include contentWidthConstrain();
  display: grid;
  gap: 2em;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  margin-top: 2em;
  margin-bottom: 2em;
  list-style: none; 
  counter-reset: li;
  

  .video-tile-wrapper {
    font-size: .5em;
    background: $white;
  
  }

  li.playlist-item {
    counter-increment: li;
    display: flex;

    &.current {
      &:before {
        font-weight: $font-weight-bold;
        background: $purple;
      }
    }

    &:before {
      content: counter(li); 
      color: $white;
      display: flex; 
      align-items: center;
      justify-content: center;
      width: 1em;
      margin-left: -1em;
      padding: 0 .3em;
      background: $pink;
      border-radius: .7em 0 0 .7em;
    }
  }
}