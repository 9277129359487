$white: #FFFFFF;
$black: #000000;
$purple: #343183;
$pink: #FF9991;
$blue: #CBE4F0;
$green: #6EB999;
$magenta: #93278F;
$yellow: #FEEF96;
$grey: #E0E0E0;

$font: 'Raleway', sans-serif;

$font-weight-thin: 100;
$font-weight-regular: 400;
$font-weight-bold: 800;